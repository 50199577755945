export class Animal {
    id: string;
    nome: string;
    descricao: string;
    status: string;

    device: string;
    station: string;
    unit: string;
    lastData: string;

    envTemperatura: string;

    constructor(){
        this.lastData = "";
    }
}
