import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AppSupportService {

    constructor(
        private alertController: AlertController,
        private toastController: ToastController,
        private loadingController: LoadingController,
    ) { }

    public async abrirStatus(message: string) {
        const toast = await this.toastController.create({color: 'dark', message, duration: 2000});
        return toast.present();
    }

    public async abrirProgresso(message, params?) {
        const loading = await this.loadingController.create({
            message,
            ...params
        });
        await loading.present();
        return loading;
    }

    public async abrirAlerta(message: string, buttons: any[] = ['OK'], params?) {
        const alert = await this.alertController.create({
            message,
            buttons,
            ...params
        });
        await alert.present();
        return alert;
    }
}
