import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ANIMAL_CL } from '../../../shared';
import { map } from 'rxjs/operators';
import { Animal } from '../../../models';

@Injectable()

export class AnimaisService {
    private ref: AngularFirestoreCollection<Animal>;

    constructor(private afs: AngularFirestore) {
        this.ref = this.afs.collection<Animal>(ANIMAL_CL, ref => ref.orderBy('nome').limit(1000));
    }

    /*Recebe ID de uma estação por pâmetro e retorna a lista dos animais que possuem essa estação*/
    listarEstacoes(stationId: string) {
        let refAnimalEstacao: AngularFirestoreCollection<Animal>;
        refAnimalEstacao = this.afs.collection<Animal>(ANIMAL_CL, ref => ref.where('station', '==', stationId));

        return refAnimalEstacao.snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        if (data.lastData) {
                            data.lastData = new Date(parseInt(data.lastData, 10) * 1000).toLocaleString('en-GB');
                        }
                        return { id, ...data };
                    }))
            );
    }

    listar() {
        return this.ref.snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        if (data.lastData) {
                            data.lastData = new Date(parseInt(data.lastData, 10) * 1000).toLocaleString('en-GB');
                        }
                        return { id, ...data };
                    }))
            );
    }

    filtrar(busca: string, tipo: string) {
        return this.afs.collection<Animal>(ANIMAL_CL, ref => ref
            .where(tipo, '>=', busca)
            // TODO: Verificar alternativa para o 'z', tentar usar o ultimo ASCII
            .where(tipo, '<=', busca + 'z')
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
            );
    }

    ordenar(ativo: string, direcao: any) {
        return this.afs.collection<Animal>(ANIMAL_CL, ref => ref
            .orderBy(ativo, direcao)
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
            );
    }

    adicionar(animal: Animal): Promise<void> {
        return this.ref.add(JSON.parse(JSON.stringify(animal))).then(() => { });
    }

    atualizar(id: string, animal: Animal): Promise<void> {
        delete animal.id;
        return this.ref.doc<Animal>(id).update(animal);
    }

    remover(id: string) {
        return this.ref.doc<Animal>(id).delete();
    }
}
