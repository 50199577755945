import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireDatabaseModule} from '@angular/fire/database';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {GruposService} from './pages/privado/grupos/grupos.service';
import {AnimaisService} from './pages/privado/rebanho/animal.service';
import {DispositivosService} from './pages/privado/dispositivos/dispositivos.service';
import {DashboardService} from './pages/privado/dashboard/dashboard.service';
import { EstacoesService } from './pages/privado/estacoes/estacoes.service';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        BrowserAnimationsModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        GruposService,
        AnimaisService,
        DispositivosService,
        DashboardService,
        EstacoesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
