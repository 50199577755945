export const CODE_ERRORS = {
    /* Traduzidos*/
    'argument-error': 'Erro de argumento',
    'app-not-authorized': 'Aplicação não autorizada',
    'app-not-installed': 'Aplicação não instalada',
    'credential-already-in-use': 'Crendencial já está em uso',
    'invalid-email': 'E-mail inválido',
    'email-already-in-use': 'E-mail já está em uso',
    'wrong-password': 'Senha incorreta',
    'weak-password': 'Senha fraca',
    'captcha-check-failed': 'Verificação de captcha falhou',
    'code-expired': 'Código expirado',
    'cordova-not-ready': 'Cordova não preparado',
    'requires-recent-login': 'Requer login recente',
    'dynamic-link-not-activated': 'Link Dinâmico não ativo',
    'expired-action-code': 'Código de ação expirado',
    'cancelled-popup-request': 'Requisição popup cancelada',
    'internal-error': 'Erro interno',
    'invalid-app-credential': 'Credencial da aplicação inválida',
    'invalid-app-id': 'ID da aplicação inválida',
    'invalid-user-token': 'Token de usuário inválido',
    'invalid-auth-event': 'Evento de autenticação inválido',
    'invalid-verification-code': 'Código de verificação inválido',
    'invalid-cordova-configuration': 'Configuração do cordova inválido',
    'invalid-custom-token': 'Token personalizado inválido',
    'invalid-api-key': 'Chave da aplicação inválida',
    'invalid-credential': 'Credencial inválida',
    'unauthorized-domain': 'Domínio não autorizado',
    'invalid-action-code': 'Código de ação inválido',
    'invalid-identifier-number': 'Número do identificador inválido',
    'invalid-recipient-email': 'Email do destinatário inválido',
    'invalid-sender': 'Remetente inválido',
    'invalid-verification-id': 'ID de verificação inválida',
    'auth-domain-config-required': 'Requer configuração do domínio de autenticação',
    'missing-app-credential': 'Credencial de aplicativo perdida',
    'missing-verification-code': 'Código de verificação perdido',
    'missing-identifier-number': 'Número de identificação perdido',
    'missing-verification-id': 'ID de verificação perdido',
    'app-deleted': 'Aplicação deletada',
    'account-exists-with-different-credential': 'Conta existe com credencial diferente',
    'network-request-failed': 'Requisição de rede falhou',
    'no-auth-event': 'Sem evento de autenticação',
    'no-such-provider': 'Nenhum provedor desse tipo',
    'operation-not-allowed': 'Operação não permitida',
    'operation-not-supported-in-this-environment': 'Operação não suportada para este ambiente',
    'provider-already-linked': 'Provedor já vinculado',
    'redirect-cancelled-by-user': 'Redirecionamento cancelado pelo usuário',
    'redirect-operation-pending': 'Operação de redirecionamento pendente',
    'timeout': 'Tempo esgotado',
    'user-token-expired': 'Token de usuário expirado',
    'too-many-requests': 'Muitas requisições',
    'user-not-found': 'Usuário não encontrado',
    'user-cancelled': 'Usuário cancelado',
    'user-disabled': 'Usuário desabilitado',
    'user-mismatch': 'Incompatibilidade de usuário',
    'user-signed-out': 'Usuário desconectado',
    'web-storage-unsupported': 'Armazenamento Web não suportado',
    'custom-token-mismatch': 'Falta de correspondência de token personalizado',
    'invalid-message-payload': 'Carga útil da mensagem inválida.',
    'invalid-oauth-provider': 'Provedor OAuth inválido',
    'missing-iframe-start': 'Falta o iframe',
    'popup-blocked': 'Popup bloqueado',
    'popup-closed-by-user': 'Popup fechado pelo usuário',
    /* TODO: Traduzir! */
    'cors-unsupported': 'cors-unsupported',
    'quota-exceeded': 'quota-exceeded',
    'auth/claims-too-large': '',
    'auth/id-token-expired': '',
    'auth/id-token-revoked': '',
    'auth/invalid-argument': '',
    'auth/invalid-claims': '',
    'auth/invalid-continue-uri': '',
    'auth/invalid-creation-time': '',
    'auth/invalid-disabled-field': '',
    'auth/invalid-display-name': '',
    'auth/invalid-dynamic-link-domain': '',
    'auth/invalid-email-verified': '',
    'auth/invalid-email': 'Email Inválido!!!',
    'auth/invalid-hash-algorithm': '',
    'auth/invalid-hash-block-size': '',
    'auth/invalid-hash-derived-key-length': '',
    'auth/invalid-hash-key': '',
    'auth/invalid-hash-memory-cost': '',
    'auth/invalid-hash-parallelization': '',
    'auth/invalid-hash-rounds': '',
    'auth/invalid-hash-salt-separator': '',
    'auth/invalid-id-token': '',
    'auth/invalid-last-sign-in-time': '',
    'auth/invalid-page-token': '',
    'auth/invalid-password': '',
    'auth/invalid-password-hash': '',
    'auth/invalid-password-salt': '',
    'auth/invalid-phone-number':	'',
    'auth/invalid-photo-url': '',
    'auth/invalid-provider-data': '',
    'auth/invalid-provider-id': '',
    'auth/invalid-session-cookie-duration': '',
    'auth/invalid-uid': '',
    'auth/invalid-user-import': '',
    'auth/maximum-user-count-exceeded': '',
    'auth/missing-android-pkg-name': '',
    'auth/missing-continue-uri': '',
    'auth/missing-hash-algorithm': '',
    'auth/missing-ios-bundle-id': '',
    'auth/missing-uid': '',
    'auth/reserved-claims': '',
    'auth/session-cookie-expired': '',
    'auth/session-cookie-revoked': '',
    'auth/uid-already-exists': '',
    'auth/unauthorized-continue-uri': '',
    'auth/email-already-exists': '',
    'auth/user-not-found': '',
    'auth/operation-not-allowed': '',
    'auth/invalid-credential': '',
    'auth/phone-number-already-exists': '',
    'auth/project-not-found': '',
    'auth/insufficient-permission': '',
    'auth/internal-error': '',
};
export const MESSAGE_ERRORS = {
    /* Traduzidos*/
    'credential-already-in-use': 'Esta credencial já está associada à uma conta de usuário diferente.',
    'invalid-email': 'O endereço de e-mail está mal formatado.',
    'email-already-in-use': 'O endereço de e-mail já está sendo utilizado por outra conta.',
    'wrong-password': 'A senha é inválida ou o usuário não tem uma senha.',
    'weak-password': 'A senha deve conter 6 ou mais caracteres.',
    'app-not-authorized': 'Este aplicativo, identificado pelo domínio onde está hospedado, não está autorizado a usar a Firebase ' +
        'Authentication com a chave API fornecida. Revise sua configuração de chave no console do Google API.',
    'app-not-installed': 'O aplicativo móvel solicitado correspondente ao identificador (nome do pacote Android ou ID do pacote iOS) fornecido não está instalado neste dispositivo.',
    'cordova-not-ready': 'O framework Cordova não está pronto.',
    'cors-unsupported': 'Este navegador não é suportado.',
    'custom-token-mismatch': 'O token personalizado corresponde a um público diferente.',
    'requires-recent-login': 'Esta operação é sensível e requer autenticação recente. Faça o login novamente antes de tentar ' +
        'novamente esta solicitação.',
    'dynamic-link-not-activated': 'Por favor, ative os Links Dinâmicos no Firebase Console e aceite os termos e condições.',
    'expired-action-code': 'O código de ação expirou.',
    'cancelled-popup-request': 'Esta operação foi cancelada devido a outro popup conflitante sendo aberto.',
    'internal-error': 'Ocorreu um erro interno.',
    'invalid-app-id': 'O identificador do aplicativo móvel não está registrado para o projeto atual.',
    'invalid-user-token': 'A credencial do usuário não é mais válida. O usuário deve fazer login novamente.',
    'invalid-auth-event': 'Ocorreu um erro interno.',
    'invalid-cordova-configuration': 'Os seguintes plugins Cordova devem ser instalados para habilitar o login do OAuth: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser e cordova-plugin-customurlscheme.',
    'invalid-custom-token': 'O formato do token personalizado está incorreto. Verifique a documentação.',
    'invalid-api-key': 'Sua chave API é inválida, verifique se você copiou corretamente.',
    'invalid-credential': 'A credencial de autenticação fornecida está mal formada ou expirou.',
    'invalid-message-payload': 'O modelo de e-mail correspondente a esta ação contém caracteres inválidos na sua mensagem. Por favor, corrija indo na seção de modelos de e-mail Auth no Firebase Console.',
    'invalid-oauth-provider': 'O EmailAuthProvider não é suportado para esta operação. Esta operação apenas oferece suporte a provedores OAuth.',
    'unauthorized-domain': 'Este domínio não está autorizado para operações OAuth para seu projeto Firebase. Edite a lista de domínios autorizados da Firebase Console.',
    'invalid-action-code': 'O código de ação é inválido. Isso pode acontecer se o código estiver malformado, expirado ou já foi usado.',
    'invalid-recipient-email': 'O e-mail correspondente a esta ação não foi enviado, pois o endereço de e-mail do destinatário fornecido é inválido.',
    'invalid-sender': 'O modelo de e-mail correspondente a esta ação contém um e-mail ou nome do remetente inválido. Por favor, corrija indo na seção de modelos de e-mail Auth no Firebase Console.',
    'invalid-verification-id': '',
    'missing-iframe-start': 'Ocorreu um erro interno.',
    'auth-domain-config-required': 'Certifique-se de incluir authDomain ao chamar firebase.initializeApp (), seguindo as instruções no Firebase Console.',
    'app-deleted': 'Esta instância do FirebaseApp foi excluída.',
    'account-exists-with-different-credential': 'Uma conta já existe com o mesmo endereço de e-mail, mas credenciais de login diferentes. Faça login usando um provedor associado a este endereço de e-mail.',
    'network-request-failed': 'Ocorreu um erro de rede (como tempo limite, conexão interrompida ou host inacessível).',
    'no-auth-event': 'Ocorreu um erro interno.',
    'no-such-provider': 'O usuário não estava vinculado a uma conta com o provedor fornecido.',
    'operation-not-allowed': 'O provedor de login fornecido está desativado para este projeto Firebase. Habilite-o no Firebase Console, debaixo do separador do método de login da seção Auth.',
    'operation-not-supported-in-this-environment': 'Esta operação não é suportada no ambiente em que este aplicativo está sendo executado. "location.protocol" deve ser http, https ou chrome-extension e o armazenamento na Web deve ser ativado.',
    'popup-blocked': 'Não é possível estabelecer uma conexão com o popup. Pode ter sido bloqueado pelo navegador.',
    'popup-closed-by-user': 'O popup foi fechado pelo usuário antes de finalizar a operação.',
    'provider-already-linked': 'O usuário só pode ser vinculado a uma identidade para o provedor fornecido.',
    'redirect-cancelled-by-user': 'A operação de redirecionamento foi cancelada pelo usuário antes de finalizar.',
    'redirect-operation-pending': 'Uma operação de início de sessão de redirecionamento já está pendente.',
    'timeout': 'A operação excedeu o tempo limite.',
    'user-token-expired': 'A credencial do usuário não é mais válida. O usuário deve fazer login novamente.',
    'too-many-requests': 'Bloqueamos todos os pedidos deste dispositivo devido a atividades incomuns. Tente mais tarde.',
    'user-cancelled': 'O usuário não concedeu a sua aplicação as permissões solicitadas.',
    'user-not-found': 'Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.',
    'user-disabled': 'A conta de usuário foi desabilitada por um administrador.',
    'user-mismatch': 'As credenciais fornecidas não correspondem ao usuário anteriormente assinado.',
    'web-storage-unsupported': 'Este navegador não é suportado ou os cookies e dados de terceiros podem estar desativados.',
    /* TODO: Traduzir! ou preencher*/
    'argument-error': '',
    'quota-exceeded': '',
    'user-signed-out': '',
    'captcha-check-failed': '',
    'code-expired': '',
    'invalid-app-credential': '',
    'invalid-verification-code': '',
    'invalid-identifier-number': '',
    'missing-app-credential': '',
    'missing-verification-code': '',
    'missing-identifier-number': '',
    'missing-verification-id': '',
    'auth/claims-too-large': '	O payload de declarações fornecido para setCustomUserClaims() excede o tamanho máximo permitido de 1.000 bytes.',
    'auth/id-token-expired': '	O token de código do Firebase provisionado expirou.',
    'auth/id-token-revoked': '	O token de código do Firebase foi revogado.',
    'auth/invalid-argument': '	Um argumento inválido foi fornecido a um método do Authentication. A mensagem de erro precisa conter informações adicionais.',
    'auth/invalid-claims': '	Os atributos de declaração personalizados fornecidos para setCustomUserClaims() são inválidos.',
    'auth/invalid-continue-uri': '	O URL de confirmação precisa ser uma string de URL válida.',
    'auth/invalid-creation-time': '	O horário da criação precisa ser um string de data UTC válido.',
    'auth/invalid-disabled-field': '	O valor fornecido para a propriedade de usuário disabled é inválido. Precisa ser um valor booleano.',
    'auth/invalid-display-name': '	O valor fornecido para a propriedade de usuário displayName é inválido. Precisa ser uma string não vazia.',
    'auth/invalid-dynamic-link-domain': '	O domínio de link dinâmico fornecido não está configurado ou autorizado para o projeto atual.',
    'auth/invalid-email-verified': '	O valor fornecido para a propriedade de usuário emailVerified é inválido. Precisa ser um valor booleano.',
    'auth/invalid-email': '	O valor fornecido para a propriedade de usuário email é inválido. Precisa ser um endereço de e-mail de string.',
    'auth/invalid-hash-algorithm': '	O algoritmo de hash precisa corresponder a um dos strings na lista de algoritmos compatíveis.',
    'auth/invalid-hash-block-size': '	O tamanho do bloco de hash precisa ser um número válido.',
    'auth/invalid-hash-derived-key-length': '	O tamanho da chave derivada do hash precisa ser um número válido.',
    'auth/invalid-hash-key': '	A chave de hash precisa ter um buffer de byte válido.',
    'auth/invalid-hash-memory-cost': '	O custo da memória hash precisa ser um número válido.',
    'auth/invalid-hash-parallelization': '	O carregamento em paralelo do hash precisa ser um número válido.',
    'auth/invalid-hash-rounds': '	O arredondamento de hash precisa ser um número válido.',
    'auth/invalid-hash-salt-separator': '	O campo do separador de "salt" do algoritmo de geração de hash precisa ser um buffer de byte válido.',
    'auth/invalid-id-token': '	O token de código informado não é um token de código do Firebase válido.',
    'auth/invalid-last-sign-in-time': '	O último horário de login precisa ser um string de data UTC válido.',
    'auth/invalid-page-token': '	O token fornecido de próxima página em listUsers() é inválido. Precisa ser uma string não vazia válida.',
    'auth/invalid-password': '	O valor fornecido para a propriedade de usuário password é inválido. Precisa ser uma string com pelo menos seis caracteres.',
    'auth/invalid-password-hash': '	O hash da senha precisa ser um buffer de byte válido.',
    'auth/invalid-password-salt': '	O "salt" da senha precisa ser um buffer de byte válido',
    'auth/invalid-phone-number':	'O valor fornecido para o phoneNumber é inválido. Ele precisa ser uma string de identificador compatível com o padrão E.164 não vazio.',
    'auth/invalid-photo-url': '	O valor fornecido para a propriedade de usuário photoURL é inválido. Precisa ser um URL de string.',
    'auth/invalid-provider-data': '	O providerData precisa ser uma matriz válida de objetos UserInfo.',
    'auth/invalid-provider-id': '	O providerId precisa ser um string de identificador de provedor compatível válido.',
    'auth/invalid-session-cookie-duration': '	A duração do cookie da sessão precisa ser um número válido em milissegundos entre 5 minutos e 2 semanas.',
    'auth/invalid-uid': '	O uid fornecido precisa ser uma string não vazia com no máximo 128 caracteres.',
    'auth/invalid-user-import': '	O registro do usuário a ser importado é inválido.',
    'auth/maximum-user-count-exceeded': '	O número máximo permitido de usuários a serem importados foi excedido.',
    'auth/missing-android-pkg-name': '	Um nome de pacote Android precisa ser fornecido para a instalação do app Android.',
    'auth/missing-continue-uri': '	Um URL de confirmação válido precisa ser fornecido na solicitação.',
    'auth/missing-hash-algorithm': '	É necessário fornecer o algoritmo de geração de hash e seus parâmetros para importar usuários com hashes de senha.',
    'auth/missing-ios-bundle-id': '	A solicitação está sem o ID do pacote do iOS.',
    'auth/missing-uid': '	Um identificador uid é necessário para a operação atual.',
    'auth/reserved-claims': '	Uma ou mais declarações de usuário personalizadas fornecidas para setCustomUserClaims() são reservadas. Por exemplo, não use as declarações específicas do OIDC, como (sub, iat, iss, exp, aud, auth_time etc.) como chaves para declarações personalizadas.',
    'auth/session-cookie-expired': '	O cookie da sessão do Firebase fornecido expirou.',
    'auth/session-cookie-revoked': '	O cookie da sessão do Firebase foi revogado.',
    'auth/uid-already-exists': '	O uid fornecido já está em uso por um usuário existente. Cada usuário precisa ter um uid exclusivo.',
    'auth/unauthorized-continue-uri': '	O domínio da URL de confirmação não está na lista de permissões. Acesse o Console do Firebase para colocar o domínio na lista de permissões.',
    'auth/email-already-exists': '	O e-mail fornecido já está em uso por outro usuário. Cada usuário precisa ter um e-mail exclusivo.',
    'auth/user-not-found': '	Não há registro de usuário existente correspondente ao identificador fornecido.',
    'auth/operation-not-allowed': '	O provedor de login fornecido está desativado para o projeto do Firebase. Ative-o na seção Método de login do Console do Firebase.',
    'auth/invalid-credential': '	A credencial usada para autenticar os SDKs Admin não pode ser usada para executar a ação desejada. Determinados métodos do Authentication, como createCustomToken() e verifyIdToken(), exigem que o SDK seja inicializado com uma credencial de certificado, e não com um token de atualização ou credencial padrão do aplicativo. Consulte Inicializar o SDK para ver a documentação sobre como autenticar os Admin SDKs com uma credencial de certificado.',
    'auth/phone-number-already-exists': '	O phoneNumber fornecido já está em uso por um usuário existente. Cada usuário precisa ter um phoneNumber exclusivo.',
    'auth/project-not-found': '	Nenhum projeto do Firebase foi encontrado com a credencial usada para inicializar os Admin SDKs. Consulte Adicionar o Firebase ao seu app para ver a documentação sobre como gerar uma credencial para o seu projeto e usá-la para autenticar os Admin SDKs.',
    'auth/insufficient-permission': '	A credencial usada para inicializar o Admin SDK não tem permissão para acessar o recurso solicitado do Authentication. Consulte Adicionar o Firebase ao seu app para ver a documentação sobre como gerar uma credencial com permissões adequadas e usá-la para autenticar os Admin SDKs.',
    'auth/internal-error': '	O servidor do Authentication encontrou um erro inesperado ao tentar processar a solicitação. A mensagem de erro precisa conter a resposta do servidor do Authentication com informações adicionais. Se o erro persistir, informe o problema ao nosso canal de suporte de Relatório do bug.',
};
