import { Injectable } from '@angular/core';
import { Dispositivo } from '../../../models';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { DISPOSITIVO_CL } from '../../../shared';

@Injectable()

export class DispositivosService {
    private ref: AngularFirestoreCollection<Dispositivo>;

    constructor(private afs: AngularFirestore) {
        this.ref = this.afs.collection<Dispositivo>(DISPOSITIVO_CL, ref => ref.orderBy('nome').limit(1000));
    }

    listar() {
        return this.ref.snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
            );
    }

    filtrar(busca: string, tipo: string) {
        return this.afs.collection<Dispositivo>(DISPOSITIVO_CL, ref => ref
            .where(tipo, '>=', busca)
            // TODO: Verificar alternativa para o 'z', tentar usar o ultimo ASCII
            .where(tipo, '<=', busca + 'z')
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
            );
    }


    ordenar(ativo: string, direcao: any) {
        return this.afs.collection<Dispositivo>(DISPOSITIVO_CL, ref => ref
            .orderBy(ativo, direcao)
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
            );
    }

    adicionar(dispositivo: Dispositivo): Promise<void> {
        console.log(dispositivo);
        return this.ref.add(JSON.parse(JSON.stringify(dispositivo))).then(() => { });
    }

    atualizar(id: string, dispositivo: Dispositivo): Promise<void> {
        dispositivo.mac = dispositivo.id;
        delete dispositivo.id;
        return this.ref.doc<Dispositivo>(id).set(dispositivo, { merge: true });
    }

    remover(id: string) {
        return this.ref.doc<Dispositivo>(id).delete();
    }
}
