import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {AuthenticationService} from '../services';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
    constructor(
        private authService: AuthenticationService,
        private navCtrl: NavController
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthorized();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthorized();
    }

    canLoad(route: Route): Observable<boolean> {
        return this.isAuthorized();
    }

    private isAuthorized(): Observable<boolean> {
        return this.authService.isAuthenticated().pipe(
            take(1),
            tap(value => {
                if (!value) {
                    this.navCtrl.navigateRoot(['./login']);
                }
            })
        );
    }
}
