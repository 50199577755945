import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AnimaisService } from '../rebanho/animal.service';

@Injectable()

export class DashboardService {
  animalService: AnimaisService;

  constructor(private afs: AngularFirestore) { }

  /*id: id do documento(id do animal, id da estacao etc.)
    colecao: nome da coleção (envTemperature, envHumidity, bodyTemperature etc.)
    nomeDoc: nome do documento (animals, devices, stations)
    dataInicio e dataFim: epochsTimestamp*/
  getCollection(idDocumento: string, colecao: string, nomeDoc: string, dataIncio: string, dataFim: string) {
    return this.afs.collection(nomeDoc).doc(idDocumento)
      .collection(colecao, data => data
        .where(firebase.firestore.FieldPath.documentId(), '>=', dataIncio)
        .where(firebase.firestore.FieldPath.documentId(), '<=', dataFim)
      ).get();
  }

  /*Retorna o vetor de animals*/
  getIdsAnimaisDaEstacao(idEstacao: string) {
    return this.afs.collection('stations').doc(idEstacao).valueChanges();
  }

  getNomeAnimais(idAnimal: string) {
    return this.afs.collection('animals').doc(idAnimal).valueChanges();
  }
}
