import {Injectable} from '@angular/core';
import {Grupo} from '../../../models';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {GRUPOS_CL} from '../../../shared';

@Injectable()

export class GruposService {
    private ref: AngularFirestoreCollection<Grupo>;

    constructor(private afs: AngularFirestore) {
        this.ref = this.afs.collection<Grupo>(GRUPOS_CL, ref => ref.orderBy('nome').limit(1000));
    }

    listar() {
        return this.ref.snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return {id, ...data};
                    }))
            );
    }

    filtrar(busca: string, tipo: string) {
        return this.afs.collection<Grupo>(GRUPOS_CL, ref => ref
            .where(tipo, '>=', busca)
            // TODO: Verificar alternativa para o 'z', tentar usar o ultimo ASCII
            .where(tipo, '<=', busca + 'z')
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return {id, ...data};
                    }))
            );
    }


    ordenar(ativo: string, direcao: any) {
        return this.afs.collection<Grupo>(GRUPOS_CL, ref => ref
            .orderBy(ativo, direcao)
        )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return {id, ...data};
                    }))
            );
    }

    adicionar(grupo: Grupo): Promise<void> {
        return this.ref.add(JSON.parse(JSON.stringify(grupo))).then(() => {});
    }

    atualizar(id: string, grupo: Grupo): Promise<void> {
        delete grupo.id;
        return this.ref.doc<Grupo>(id).update(grupo);
    }

    remover(id: string) {
        return this.ref.doc<Grupo>(id).delete();
    }
}
