export class Usuario {
    constructor(value?: firebase.User) {
        if (value) {
            this.id = value.uid;
            this.email = value.email;
            this.nome = value.displayName;
            this.fotoURL = value.photoURL;
        }
    }

    id: string;
    email: string;
    nome: string;
    fotoURL: string;
}
