import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth-guards.service';

const authGuards = {
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
};

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
    },
    {
        path: '',
        loadChildren: () => import('./pages/privado/privado.module').then(m => m.PrivadoPageModule),
        ...authGuards
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
