export class Dispositivo {
    id: string;
    mac: string;
    nome: string;
    descricao: string;
    status: string;

    animal: string;
    station: string;
    unit: string;
    lastData: string;
    constructor(){
        this.lastData = "";
    }
}
