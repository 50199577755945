// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyAJw3Ob1LTJpJyFBQ84e8m71e-nhXrs6L8',
        authDomain: 'bep-homolog.firebaseapp.com',
        databaseURL: 'https://bep-homolog.firebaseio.com',
        projectId: 'bep-homolog',
        storageBucket: 'bep-homolog.appspot.com',
        messagingSenderId: '277693523048',
        appId: '1:277693523048:web:96c19606f1ea0e96c147dd'
    }
};**/
export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyDrsE60yZ0xa51sXFW9Zh9K_mj8fYyONA8",
        authDomain: "bep-novo.firebaseapp.com",
        databaseURL: "https://bep-novo.firebaseio.com",
        projectId: "bep-novo",
        storageBucket: "bep-novo.appspot.com",
        messagingSenderId: "176904849555",
        appId: "1:176904849555:web:087a16d9545b3285c76566",
        measurementId: "G-TLP0RY1R5E"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
