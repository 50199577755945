import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {NavController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Usuario} from '../models';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    get $usuarioAtual() {
        return this.afAuth.authState
            .pipe(
                map(value => new Usuario(value))
            );
    }

    constructor(
        private afAuth: AngularFireAuth,
        private navCtrl: NavController
    ) { }

    async login(email: string, senha: string): Promise<void> {
        await this.afAuth.auth.signOut();
        await this.afAuth.auth.signInWithEmailAndPassword(email, senha)
            .then(() => {
                console.log('Logado com Sucesso!');
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
        await this.navCtrl.navigateRoot(['./dashboard']);
    }

    async logout(): Promise<void> {
        await this.afAuth.auth.signOut();
        await this.navCtrl.navigateRoot(['./login']);
    }

    isAuthenticated(): Observable<boolean> {
        return this.afAuth.authState.pipe(
            map(value => !!value)
        );
    }
}
