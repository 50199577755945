/**export const GRUPOS_CL = 'grupos';
export const ANIMAL_CL = 'animais';
export const DISPOSITIVO_CL = 'dispositivos';
export const USUARIO_CL = 'usuarios';*/

export const GRUPOS_CL = 'groups';
export const ANIMAL_CL = 'animals';
export const DISPOSITIVO_CL = 'devices';
export const USUARIO_CL = 'users';
export const ESTACAO_CL = 'stations';
 